export function setupClipboardCopy() {
  document.addEventListener('click', (event) => {
    if (event.target.matches('[data-clipboard-copy]')) {
      copyToClipboard(event);
    }
  });
}

function copyToClipboard(event) {
  const shareUrl = document.getElementById('share-url');

  // Use the Clipboard API instead of selection
  navigator.clipboard.writeText(shareUrl.value)
    .then(() => {
      // Provide visual feedback
      const button = event.target;
      const originalText = button.innerText;
      button.innerText = '✅';
      setTimeout(() => {
        button.innerText = originalText;
      }, 2000);
    })
    .catch(err => {
      console.error('Failed to copy text: ', err);
    });
}
